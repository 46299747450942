<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ `${this.$t("brands.Sales")}` }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-form ref="filterForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="`Marca`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                    @change="onChangeFilterMainCredentials('brands')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.operatorId"
                    v-bind:label="`Operador`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_operators"
                    :loading="isSearching['operators']"
                    :items="filtered_operators"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                    @change="onChangeFilterMainCredentials('operators')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.chainId"
                    v-bind:label="`Cadena`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_chains"
                    :loading="isSearching['chains']"
                    :items="filtered_chains"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                    @change="onChangeFilterMainCredentials('chains')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.companyId"
                    v-bind:label="`Compañía`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_companies"
                    :loading="isSearching['companies']"
                    :items="filtered_companies"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                    @change="onChangeFilterMainCredentials('companies')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefSales"
              id="datatable_reporting_sales"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ReportingSales",
  data: function() {
    return {
      visitTypes: [],
      VISIT_TYPES: {},
      filterlist_fetch_url: "reporting/sales/getfilterlist",
      filterable_col_list: [],
      filterCriteria: {
        route: {},
        date: {},
        company_name: {},
        operator_name: {},
        user: {},
        pos: {},
        address_label: "",
        chain: {},
        subchain: {},
        sub2chain: {},
        channel: {},
        subchannel: {},
        sub2channel: {},
        brand: {},
        subbrand_name: {},
        subfamily_name: {},
        product: {},
        family_name: {},
        unit_price_without_vat: {},
        euros_sales: {},
        total_pos_sales: {},
        total_pos_sales_objective: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,
      totaldata: 0,
      data: [],

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],

      /********************** Filter Form **********************/
      filter_main_credentials_fetch_url:
        "reporting/sales/getfiltermaincredentials",

      select_startDate_menu: false,
      select_endDate_menu: false,

      filterMainCredential: {
        brandId: [],
        operatorId: [],
        date: {
          from: null,
          to: null
        },
        companyId: [],
        chainId: []
      },
      valid: true,
      isSearching: {},

      search_brands: null,
      filtered_brands: [],
      search_operators: null,
      filtered_operators: [],
      search_chains: null,
      filtered_chains: [],
      search_companies: null,
      filtered_companies: []
    };
  },
  watch: {
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_operators(val) {
      this.searchValues("operators", val);
    },
    async search_chains(val) {
      this.searchValues("chains", val);
    },
    async search_companies(val) {
      this.searchValues("companies", val);
    }
  },
  computed: {
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_company() {
      return {
        key: "company_name",
        title: "Compañía",
        field: "company_name",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.company_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["company_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`company_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["company_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "company_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_operator() {
      return {
        key: "operator_name",
        title: "Operador",
        field: "operator_name",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.operator_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["operator_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`operator_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["operator_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "operator_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={value =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: "Marca",
        field: "brand",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subbrand() {
      return {
        key: "subbrand_name",
        title: "SubMarca",
        field: "subbrand_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subbrand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subbrand_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subbrand_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subbrand_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subbrand_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_product() {
      return {
        key: "product",
        title: this.$t("Product"),
        field: "product",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.product_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["product"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`product`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["product"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "product")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_family() {
      return {
        key: "family",
        title: "Familia",
        field: "family",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.family_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["family_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`family_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["family_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "family_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subfamily() {
      return {
        key: "subfamily_name",
        title: "SubFamilia",
        field: "subfamily_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subfamily_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subfamily_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subfamily_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subfamily_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "subfamily_name")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },

    col_chain() {
      return {
        key: "chain",
        title: this.$t("chain"),
        field: "chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subchain() {
      return {
        key: "subchain",
        title: "Sub " + this.$t("chain"),
        field: "subchain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_sub2chain() {
      return {
        key: "sub2chain",
        title: "Sub-Sub " + this.$t("chain"),
        field: "sub2chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_channel() {
      return {
        key: "channel",
        title: this.$t("channel"),
        field: "channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subchannel() {
      return {
        key: "subchannel",
        title: "Sub " + this.$t("channel"),
        field: "subchannel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchannel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchannel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchannel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchannel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchannel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_sub2channel() {
      return {
        key: "sub2channel",
        title: "Sub-Sub " + this.$t("channel"),
        field: "sub2channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_unit_price_without_vat() {
      return {
        key: "unit_price_without_vat",
        title: `Unit Price`,
        field: "unit_price_without_vat",
        align: "left",
        sortBy: "",
        width: 180
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={
        //             this.filterCriteria["unit_price_without_vat"]["from"]
        //           }
        //           value={this.filterCriteria["unit_price_without_vat"]["from"]}
        //           placeholder="From - "
        //         />
        //         <br />
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["unit_price_without_vat"]["to"]}
        //           value={this.filterCriteria["unit_price_without_vat"]["to"]}
        //           placeholder="To - "
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() =>
        //               this.searchCancel(closeFn, "unit_price_without_vat")
        //             }
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_box_sales() {
      return {
        key: "salesAmount",
        title: `Units Sold`,
        field: "salesAmount",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_box_return() {
      return {
        key: "returnsAmount",
        title: `Units Returned`,
        field: "returnsAmount",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_euros_sales() {
      return {
        key: "euros_sales",
        title: `Euros Sales`,
        field: "euros_sales",
        align: "left",
        sortBy: "",
        width: 180
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["euros_sales"]["from"]}
        //           value={this.filterCriteria["euros_sales"]["from"]}
        //           placeholder="From - "
        //         />
        //         <br />
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["euros_sales"]["to"]}
        //           value={this.filterCriteria["euros_sales"]["to"]}
        //           placeholder="To - "
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "euros_sales")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_total_pos_sales() {
      return {
        key: "total_pos_sales",
        title: `Total POS Sales`,
        field: "total_pos_sales",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_total_pos_sales_objective() {
      return {
        key: "total_pos_sales_objective",
        title: `Total POS Sales Objective`,
        field: "total_pos_sales_objective",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    columns() {
      return [
        this.col_route,
        this.col_date,
        this.col_company,
        this.col_userId,
        this.col_user,
        this.col_operator,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_chain,
        this.col_subchain,
        this.col_sub2chain,
        this.col_channel,
        this.col_subchannel,
        this.col_sub2channel,
        this.col_brand,
        this.col_subbrand,
        this.col_product,
        this.col_family,
        this.col_subfamily,
        this.col_unit_price_without_vat,
        this.col_box_sales,
        this.col_box_return,
        this.col_euros_sales,
        this.col_total_pos_sales,
        this.col_total_pos_sales_objective
      ];
    }
  },
  methods: {
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "operators") {
        this.filtered_operators = resp.data;
      } else if (fieldname === "chains") {
        this.filtered_chains = resp.data;
      } else if (fieldname === "companies") {
        this.filtered_companies = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },

    onChangeDates() {
      if (
        this.filterMainCredential.date.from > this.filterMainCredential.date.to
      ) {
        this.filterMainCredential.date.to = this.filterMainCredential.date.from;
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        [
          "unit_price_without_vat",
          "euros_sales",
          "total_pos_sales",
          "total_pos_sales_objective"
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_reporting_sales"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (
            [
              "unit_price_without_vat",
              "euros_sales",
              "total_pos_sales",
              "total_pos_sales_objective"
            ].includes(key)
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#datatable_reporting_sales .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      if (!this.$refs.filterForm.validate()) return;
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "reporting/sales";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.visitTypes = resp.visitTypes;
        this.approval_status = resp.approval_status;
        this.VISIT_TYPES = resp.VISIT_TYPES;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onClickFilter() {
      if (this.$refs.filterForm.validate()) {
        this.getDataFromApi();
      }
    },
    async downloadExcel() {
      if (!this.$refs.filterForm.validate()) return;
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "reporting/sales/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    init() {
      this.filterable_col_list = Object.keys(this.filterCriteria);
      this.searchValues("brands", "");
      this.searchValues("operators", "");
      this.searchValues("companies", "");
      this.searchValues("chains", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
